import React from "react";

const ShowPdfPage = function ({ ...props }) {
  const file = require("../../resources/" + "file" + ".pdf");
  return (
    <object data={file} type="application/pdf" width={"100%"} height={"100%"} />
  );
};

export default ShowPdfPage;
